<template>
  <transition name="slide">
    <div class="flash_message_wrapper z-3000 fixed top-0 left-0 right-0" :class="type == 'notice' ? 'bg-brand-green' : 'bg-red-500'" v-show="active">
      <div class="flash_message px-4 py-8 text-white" :class="type">
        <button class="right-1 absolute" type="button" @click="active = false">
          <svg class="w-6 h-6 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18">
            <path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"></path>
          </svg>
        </button>
        <p class="mb-0 text-base">{{ tidyMessage(message) }}</p>
      </div>
    </div>
  </transition>
</template>

<script setup>
import { ref } from "vue";
const props = defineProps({
  message: {
    required: true,
    type: String,
  },
  type: {
    required: true,
    type: String,
  },
});

const active = ref(true);

setTimeout(() => {
  active.value = false;
}, 5000);

const tidyMessage = (message) => {
  if (message == "User saved.") {
    return "Your profile has been updated.";
  }
  return message;
};
</script>
