<template>
  <div class="animation-wrapper-fade-in-from-bottom" :class="[!show ? 'opacity-0' : '', !show ? 'translate-y-8' : '', preventFadeIn ? 'opacity-100' : 'transition duration-700 transform']" v-observe-visibility="observer">
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: {
    preventFadeIn: {
      required: false,
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      show: false,
      observer: { callback: this.showCard, once: true, throttle: 300 },
    };
  },
  methods: {
    showCard(isVisible) {
      this.show = isVisible;
    },
  },
};
</script>
