<template>
    <div class="flash_messages text-center">
        <flash-message v-for="flashMessage in filteredMessages" :key="flashMessage['type']" :message="flashMessage['message']" :type="flashMessage['type']"></flash-message>
    </div>
</template>

<script>

import FlashMessage from './FlashMessage.vue';

export default {
    components: {
        FlashMessage
    },
    props: {
        messages: {}
    },
    computed: {
        filteredMessages(){
            let filtersMessages = Object.entries(this.messages).filter(([type,message])=>['error','notice'].includes(type));
            return filtersMessages.map(([type,message])=>({type,message}));
        }
    }
}
</script>