<template>
  <transition enter-from-class="translate-x-full" enter-active-class="duration-250 ease-out" enter-to-class="translate-x-0" appear>
    <div v-show="showing" class="navigation z-2000 max-w-[350px] fixed inset-y-0 right-0 w-full px-6 overflow-y-auto bg-white" :class="[withBanner ? 'pt-178p' : 'pt-148p']">
      <ul class="mb-8 space-y-1">
        <li class="font-oswald bg-ma-mushroom mb-3p text-lg">
          <a href="/" class="hover:text-ma-blue text-ma-red block px-8 py-6" :class="{ 'text-ma-blue': isActive('/') }">Home</a>
        </li>
        <li v-for="entry in entries" :key="entry.id" class="font-oswald bg-ma-mushroom mb-3p text-lg">
          <a :href="entry.uri" class="hover:text-ma-blue text-ma-red block px-8 py-6" :class="{ 'text-ma-blue': isActive(entry.uri) }">
            {{ entry.title }}
          </a>
        </li>
      </ul>
    </div>
  </transition>
</template>

<script>
import SocialMediaIcons from "../SocialMediaIcons.vue";
export default {
  props: {
    showing: {
      required: true,
      type: Boolean,
    },
    entries: {
      required: true,
      type: Array,
    },
    socialMedia: {
      required: true,
      type: Object,
    },
    withBanner: {
      required: true,
      type: Boolean,
    },
  },
  components: {
    SocialMediaIcons,
  },
  methods: {
    isActive(uri) {
      return window.location.pathname.substring(1) == uri;
    },
    // entry(id) {
    //   let entry = this.entries.find((e) => e.id == id);
    //   if (!entry) return { title: null, url: null };
    //   return entry;
    // },
  },
};
</script>
